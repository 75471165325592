import { ErrorMessage } from '@/app/components/ErrorMessage'
import React, { forwardRef } from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  startDecoration?: React.ReactNode
  endDecoration?: React.ReactNode
  errorMessage?: string
}

export type Ref = HTMLInputElement

export const Input = forwardRef<Ref, InputProps>(
  (
    {
      type,
      label,
      id,
      errorMessage,
      placeholder,
      startDecoration,
      endDecoration,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col gap-2">
        {label && (
          <label className="font-sans text-mydra-black" htmlFor={id}>
            {label}
          </label>
        )}
        <div
          className={`relative w-full flex gap-2 items-center px-4 py-2 ${
            props.disabled ? 'bg-mydra-gray cursor-not-allowed' : ''
          } border-gray-200 rounded-lg bg-white text-mydra-black overflow-hidden border focus-within:outline-mydra-black focus-within:outline focus-within:outline-1 ${
            props.className || ''
          } ${errorMessage && `border-red-500`}`}
        >
          {startDecoration && (
            <div className="h-full flex items-center justify-center">
              {startDecoration}
            </div>
          )}
          <input
            {...props}
            className="flex-1 p-0 shrink-0 w-full leading-7 focus:ring-0 ring-0 appearance-none shadow-none border-none"
            type={type}
            ref={ref}
            id={id}
            placeholder={placeholder}
            style={{ fontSize: 'inherit' }}
          />
          {endDecoration && (
            <div className="h-full flex items-center justify-center">
              {endDecoration}
            </div>
          )}
        </div>

        <ErrorMessage message={errorMessage} className="mt-4 text-left" />
      </div>
    )
  }
)

Input.displayName = 'Input'
