'use client'

import { TextSearchInput } from '@/app/components/TextSearchInput'
import { useAuthLogout } from '@/app/hooks/auth/useAuthLogout'
import CheckListIcon from '@/app/icons/CheckListIcon/CheckListIcon'
import CloseIcon from '@/app/icons/CloseIcon/CloseIcon'
import MenuIcon from '@/app/icons/MenuIcon/MenuIcon'
import PersonIcon from '@/app/icons/PersonIcon/PersonIcon'
import SettingsIcon from '@/app/icons/Settings/Settings'
import WalletIcon from '@/app/icons/WalletIcon/WalletIcon'
import * as auth from '@/app/lib/auth'
import { EXTERNAL_LINKS, ROUTES } from '@/app/lib/constants'
import { LanguageSelector } from '@/app/modules/shared/components/language-selector'
import { useAuth } from '@/app/providers/Auth/Auth'
import { UserRole } from '@/types/user'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { PrimaryButton } from '../PrimaryButton'
import { HeaderMenu } from './HeaderMenu'
import { useSearchInputContext } from '@/app/modules/course/contexts/InputSearchContext'

const MENU_ROUTES = {
  title: 'menu',
  routes: [],
}

export type HeaderProps = {
  transparent?: boolean
  showLogout?: boolean
  hideButtons?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Header = ({
  transparent,
  showLogout,
  hideButtons,
  ...props
}: HeaderProps) => {
  const router = useRouter()
  const {
    isAuthenticated = false,
    firstName,
    lastName,
    roles: userRoles,
  } = useAuth()
  const { mutateAsync: logout } = useAuthLogout()
  const [menuOpen, setMenuOpen] = useState(false)
  const t = useTranslations('header')
  const { isLargeSearchVisible } = useSearchInputContext()

  const ACCOUNT_ROUTES = {
    title: 'account',
    routes: [
      { title: 'profile', route: ROUTES.myProfile, icon: PersonIcon },
      { title: 'my_orders', route: ROUTES.myOrders, icon: CheckListIcon },
      { title: 'financing', route: ROUTES.myFinancing, icon: WalletIcon },
      ...([
        UserRole.EducationProvider,
        UserRole.Employer,
        UserRole.TaxBenefitsProvider,
      ].some((userRole) => (userRoles ?? []).includes(userRole))
        ? [
            {
              route: EXTERNAL_LINKS.partnerDashboard,
              icon: SettingsIcon,
              title: 'partner_dashboard',
              target: '_blank',
            },
          ]
        : []),
    ],
  }

  const onLogout = async () => {
    try {
      await logout()
    } catch {
      // Do nothing
    }
    auth.logout(router.locale)
  }

  const currentSearchTerm = router.query.term as string

  const handleSearch = (term: string) => {
    if (router.pathname === ROUTES.search) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, term },
      })
    } else {
      router.push({ pathname: ROUTES.search, query: { term } })
    }
  }

  return (
    <header
      {...props}
      className={`${
        transparent ? 'bg-transparent' : 'bg-white border-b'
      } py-3 md:py-5 shrink-0 sticky left-0 top-0 w-full z-40 flex flex-col ${props.className ?? ''}`}
    >
      <div className="container flex flex-row gap-x-4 items-center justify-end h-full">
        <div className="flex items-center gap-9 mr-auto shrink-0">
          <Link href={ROUTES.homepage}>
            <Image
              src="/images/mydra-lg.png"
              width={1000}
              height={250}
              alt={t('logo_alt')}
              priority
              className="w-auto h-8"
            />
          </Link>
          {MENU_ROUTES.routes.map(({ title, route }) => (
            <Link href={route} className="hidden md:flex" key={route}>
              <span className="text-base text-black">{t(title)}</span>
            </Link>
          ))}
          {!isLargeSearchVisible && (
            <div className="hidden sm:block w-[300px] md:w-[430px] mx-auto">
              <TextSearchInput
                onSearch={handleSearch}
                defaultValue={currentSearchTerm || ''}
                hideFilters
              />
            </div>
          )}
        </div>
        <div className="max-xs:hidden block">
          <div className="hidden md:block">
            <LanguageSelector variant="button" />
          </div>
          <div className="block md:hidden">
            <LanguageSelector variant="button" useAbbreviatedLangName />
          </div>
        </div>

        {((!isAuthenticated && !hideButtons) || showLogout) && (
          <div className="flex gap-4 items-center justify-items-end">
            {!isAuthenticated && !hideButtons && (
              <>
                <Link
                  className="max-sm:hidden block text-mydra-black font-semibold min-w-fit hover:opacity-60"
                  href={{
                    pathname: ROUTES.login,
                    query: { redirectTo: window.location.pathname },
                  }}
                >
                  {t('login')}
                </Link>

                <PrimaryButton
                  className="min-w-fit hover:opacity-85"
                  onClick={() =>
                    router.push({
                      pathname: ROUTES.signup,
                      query: { redirectTo: window.location.pathname },
                    })
                  }
                >
                  {t('sign_up')}
                </PrimaryButton>
              </>
            )}
            {showLogout && (
              <PrimaryButton className="px-6 min-w-fit" onClick={onLogout}>
                {t('logout')}
              </PrimaryButton>
            )}
          </div>
        )}

        {isAuthenticated && (
          <>
            <HeaderMenu
              variant="dropdown"
              sections={[ACCOUNT_ROUTES]}
              onLogout={onLogout}
              userInitials={[firstName, lastName]
                .filter(Boolean)
                .map((name) => name?.[0] ?? '')
                .join('')}
              userName={[firstName, lastName]
                .filter(Boolean)
                .map((name) => name?.[0] ?? '')
                .join(' ')}
            />

            <span className="flex md:hidden" onClick={() => setMenuOpen(true)}>
              <MenuIcon />
            </span>
            {menuOpen && (
              <div className="fixed top-0 left-0 bg-white w-full h-full z-[999999] md:hidden">
                <div className="flex flex-col">
                  <div className="container py-3 md:py-5 flex flex-row items-center justify-between border-b border-mydra-gray">
                    <Link href={ROUTES.homepage}>
                      <Image
                        src="/images/mydra-lg.png"
                        width={1000}
                        height={250}
                        alt={t('logo_alt')}
                        priority
                        className="w-auto h-8"
                      />
                    </Link>
                    <button onClick={() => setMenuOpen(false)}>
                      <CloseIcon className="ml-auto" />
                    </button>
                  </div>
                  <HeaderMenu
                    sections={[MENU_ROUTES, ACCOUNT_ROUTES]}
                    onLogout={onLogout}
                    onClose={() => setMenuOpen(false)}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!isLargeSearchVisible && (
        <div className="container sm:hidden mt-3">
          <TextSearchInput
            onSearch={handleSearch}
            defaultValue={currentSearchTerm || ''}
            noIcon
            hideFilters
          />
        </div>
      )}
    </header>
  )
}
