import { useState } from 'react'
import { useTranslations } from 'next-intl'
import { Input } from '@/app/components/Input'
import { SearchBackground } from '@/app/icons/SearchBackground'
import { MdTune } from 'react-icons/md'

type TextSearchInputProps = {
  onSearch: (searchTerm: string) => void
  defaultValue?: string
  noIcon?: boolean
  onFilterClick?: (term: string) => void
  hideFilters?: boolean
}

export const TextSearchInput = ({
  onSearch,
  defaultValue = '',
  noIcon = false,
  onFilterClick,
  hideFilters = false,
  ...props
}: TextSearchInputProps) => {
  const [term, setTerm] = useState(defaultValue)
  const t = useTranslations('header')

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = e.currentTarget.value.trim()
      if (value) {
        onSearch(value)
      }
    }
  }

  const handleIconClick = () => {
    if (term && term.trim()) {
      onSearch(term.trim())
    }
  }

  return (
    <Input
      {...props}
      type="text"
      value={term}
      onChange={(e) => setTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={t('text_search_placeholder')}
      className="focus-within:outline-mydra-purple text-sm md:text-base pr-2"
      endDecoration={
        !noIcon ? (
          <div className="flex items-center justify-center gap-2">
            {!hideFilters && (
              <button
                onClick={() => onFilterClick?.(term)}
                className="flex items-center gap-1"
              >
                <MdTune />
                <span className="text-sm">{t('filters')}</span>
              </button>
            )}
            <button onClick={handleIconClick}>
              <SearchBackground />
            </button>
          </div>
        ) : null
      }
    />
  )
}
